<template>
  <div class="wrapper pb-2">
    <TipComponent :show-tutorial-handler="showTutorialHandler" />

    <FileInputComponent :params="getSearch"
                        :exibir-endereco-select="!!this.grupo"
                        :endereco-select="enderecoSelect"
                        @refresh="index()" />

    <section class="table-section">
      <hr>
      <b-row>
        <b-col
          xl="5"
          md="12"
          class="m-header"
        >
          <div
            v-if="showTable"
            class="d-flex justify-center-center align-items-center"
          >
            <span class="mr-50">Mostrar</span>
            <v-select
              id="orders"
              v-model="paginationData.defaultSize"
              :options="table.tableRows"
              :clearable="false"
              @input="updateQtdView($event)"
            >
              <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
          </div>
        </b-col>

        <b-col
          xl="7"
          md="12"
          class="text-right"
        >
          <b-form-group>
            <b-input-group
              class="input-group-merge"
            >
              <b-form-input
                id="search"
                v-model="search"
                class="form-control-merge"
                placeholder="Buscar"
                autocomplete="off"
                @keyup="handleSearch"
              />

              <b-input-group-append
                v-if="search"
                is-text
              >
                <feather-icon
                  class="cursor-pointer"
                  icon="XIcon"
                  @click="clearSearch()"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          class="text-center"
        >
          <p
            v-if="table.empty"
            class="table-empty mt-3"
          >
            Nenhum registro encontrado.
          </p>

          <p
            v-if="table.tableError"
            class="table-empty mt-3"
          >
            Sistema de busca indisponível no momento.
          </p>
        </b-col>

        <b-col
          v-if="loadingTable"
          cols="12"
        >
          <div
            v-if="table.tableBusy"
            class="spinner-area"
          >
            <b-spinner class="spinner-border text-custom-blue" />
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          v-if="showTable"
          cols="12"
          class="my-2"
        >
          <b-table
            responsive
            :busy.sync="table.tableBusy"
            :no-local-sorting="true"
            :fields="table.fields"
            :items="table.items"
            @context-changed="handleOrderTable"
          >
            <template #cell(nome)="row">
              <span>{{ row.value }}</span>
            </template>

            <template #cell(cnpj)="row">
              <span>{{ row.value | VMask("##.###.###/####-##") }}</span>
            </template>

            <template #cell(numero_linhas)="row">
              <span>{{ row.value }}</span>
            </template>

            <template #cell(vacinado_importado_count)="row">
              <span>{{ row.value }}</span>
            </template>

            <template #cell(criacao)="row">
              <span>{{ moment(row.item.data_hora).format("DD/MM/YYYY HH:mm") }}</span>
            </template>

            <template #cell(situacao)="row">
              <b-badge :variant="getBadgeVariant(row.value).variant">
                {{ getBadgeVariant(row.value).status }}
              </b-badge>
            </template>

            <template #cell(actions)="row">
              <button-icon
                :color="getDisableIcons(row.item.situacao) ? '#82868b' : '#2772C0'"
                size="18"
                feather-icon="DownloadIcon"
                class-name="btn-margin"
                :disabled="getDisableIcons(row.item.situacao)"
                @action="downloadDetails(row.item.id_planilha)"
              />
              <button-icon
                :color="getDisableIcons(row.item.situacao) ? '#82868b' : '#2772C0'"
                size="18"
                feather-icon="Trash2Icon"
                :disabled="getDisableIcons(row.item.situacao)"
                @action="showConfirmDeletionModal(row.item.id_planilha)"
              />
            </template>
          </b-table>
        </b-col>

        <b-col
          v-if="showTable"
          class="px-3"
          sm="12"
        >
          <CustomPagination
            :paginacao="paginationData"
            @page-cliked="updateCurrentPage"
          />
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BCol, BRow, BSpinner, BTable, BBadge, BFormInput, BInputGroupAppend, BInputGroup, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moment from 'moment'
import TipComponent from '@/views/custom-pages/Vacinacao/Pages/Importacao/TipComponent.vue'
import FileInputComponent from '@/views/custom-pages/Vacinacao/Pages/Importacao/FileInputComponent.vue'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'
import ButtonIcon from '@/views/components/custom/Buttons/ButtonIcon/ButtonIcon.vue'
import { warningMessage, warningMessageRemoveSpreadsheet } from '@/libs/sweetalerts'

export default {
  components: {
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    ButtonIcon,
    BRow,
    BCol,
    BBadge,
    CustomPagination,
    BSpinner,
    BTable,
    FileInputComponent,
    TipComponent,
    vSelect,
  },
  props: {
    showTutorialHandler: Function,
    params: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      moment,

      search: '',

      status: {
        SUCESSO: 'Sucesso',
        ERRO: 'Erro',
        PENDENTE: 'Pendente',
        ANDAMENTO: 'Em andamento',
        EXCLUIDO: 'Excluído',
      },

      loadingTable: false,

      showTable: false,

      paginationData: {
        currentPage: 0,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
        defaultSize: 10,
      },

      loadingData: false,
      rowToDeleteId: null,
      showDeleteModal: false,
      showErrorModal: false,
      table: {
        empty: false,
        tableError: false,
        tableBusy: true,
        tableRows: [10, 25, 50, 100],
        tableOrder: '',
        tableColumnNameOrder: '',
        fields: [
          { key: 'nome', label: 'NOME DA PLANILHA', sortable: true },
          { key: 'cnpj', label: 'CNPJ' },
          { key: 'numero_linhas', label: 'APLICAÇÕES IDENTIFICADAS' },
          { key: 'vacinado_aplicado_count', label: 'APLICAÇÕES INSERIDAS' },
          { key: 'criacao', label: 'DATA DA IMPORTAÇÃO', sortable: true },
          { key: 'situacao', label: 'STATUS', sortable: true },
          { key: 'actions', label: 'AÇÕES' },
        ],
        items: [],
      },
      enderecoSelect: []
    }
  },

  computed: {
    getSearch() {
      return this.params
    },

    idCampanha() {
      return this.getSearch.campanha.id_campanha
    },

    empresa() {
      return this.getSearch.empresa
    },

    unidade() {
      return this.getSearch.unidade
    },

    grupo() {
      return this.getSearch.grupo
    },

    descricaoCampanha() {
      return this.getSearch.campanha.descricao_campanha
    },
    planilhaProcessada() {
        let notifications =
          this.$store.getters["notifications/getNotificationsBell"].itens;
        return notifications != null ? notifications.length : 0;
    },
  },

  watch: {
    search(value) {
      if (value.length === 0) {
        this.search = ''
        this.index()
      }
    },
    "planilhaProcessada": {
      immediate: true,
      handler() {
        this.index();
      },
    },
  },

  mounted() {
    this.index()

    if (this.grupo) {
      this.loadAddresses(this.empresa.id_empresa, this.unidade.idUnit);
    }
  },

  methods: {
    index() {
      this.table.tableBusy = true

      this.table.tableError = false
      this.table.empty = false

      this.table.items = []

      const params = {
        colunaNome: this.table.tableColumnNameOrder,
        colunaOrdem: this.table.tableOrder,
        tamanho: this.paginationData.defaultSize,
        pagina: this.paginationData.currentPage,
        id_unidade: !this.grupo ? this.unidade.id_unidade : this.unidade.idUnit,
        id_empresa: this.empresa.id_empresa,
        id_campanha: !this.grupo ? this.idCampanha : this.getSearch.campanha.idCampaign,
        termoPesquisa: this.search,
      }

      this.$http.get(this.$api.listarImportacaoDeVacinados(), { params })
        .then(response => {
          if (response.status === 200) {
            if (response.data.data.length > 0) {
              this.showTable = true
              this.table.items = response.data.data
              this.table.tableBusy = false
              this.handlePagination(response.data)
              return
            }

            this.table.empty = true
            this.showTable = false
            this.table.tableBusy = false
          }
        })
        .catch(() => {
          this.table.tableError = true
          this.table.tableBusy = false
          this.showTable = false
        })
    },

    async loadAddresses(companyId, unitId) {
      await this.$http.get(this.$api.getShowAndEditEmpresa(companyId)).then(({data}) => {
        this.enderecoSelect = data.enderecos.filter(address => address.id_unidade === unitId);
      });
    },

    handleSearch() {
      if (this.search.length > 3) {
        this.paginationData = {
          currentPage: 0,
          totalLines: 0,
          fromLine: 0,
          toLine: 0,
          defaultSize: 10,
        }

        this.index()
      }
    },

    clearSearch() {
      if (this.search.length > 3) {
        this.search = ''
        this.index()
      } else {
        this.search = ''
      }
    },

    showConfirmDeletionModal(id) {
      warningMessageRemoveSpreadsheet().then(() => {
        this.deleteImportedItemHandler(id)
      })
    },

    async downloadDetails(id) {
      await this.$http.get(this.$api.downloadImportacaoVacinados(id))
        .then(response => {
          const content = response.data
            .map(item => ({
              VACINADO: item.situacao === 'Aplicado' ? 'Sim' : 'Não',
              'DATA DE APLICAÇÃO': item.data_aplicacao ? moment(item.data_aplicacao).format('DD/MM/YYYY') : '',
              'CPF VACINADOR': item.cpf_vacinador,
              LOTE: item.lote,
              NOME: item.nome,
              CPF: item.cpf,
              CATEGORIA: item.categoria,
              CNPJ: item.cnpj,
              "ID EMPRESA ENDEREÇO": item.id_empresa_endereco,
              "ENDEREÇO": item.endereco_completo,
              ERRO: item.erro,
            }))

          this.$helpers.downloadArchiveXlsx(content, 'resumo_importação.xlsx')
        })
        .catch(() => {
        })
    },

    async deleteImportedItemHandler(id) {
      await this.$http.delete(this.$api.deletarImportacaoDeVacinados(id))
        .then(response => {
          if (response.status === 204) {
            this.paginationData = {
              currentPage: 0,
              totalLines: 0,
              fromLine: 0,
              toLine: 0,
              defaultSize: 10,
            }

            this.index()
          }
        })
        .catch(error => {
          this.handleError(error.response)
        })
    },

    handleError(response) {
      const errors = response.status === 400 || response.status === 404

      const message = 'Não foi possível realizar a sua solicitação. Entre em contato com o suporte.'

      if (errors) {
        warningMessage(response.data.error || message)

        return
      }

      warningMessage(message)
    },

    handleOrderTable(context) {
      this.table.tableColumnNameOrder = context.sortBy
      this.table.tableOrder = context.sortDesc ? 'desc' : 'asc'

      this.index()
    },

    handlePagination(data) {
      this.paginationData.fromLine = data.from
      this.paginationData.toLine = data.to
      this.paginationData.totalLines = data.total
      this.paginationData.currentPage = data.current_page
    },

    updateQtdView(event) {
      if (!event) {
        this.paginationData.defaultSize = 10
      }

      this.paginationData.currentPage = 1
      this.index()
    },

    updateCurrentPage(page) {
      this.paginationData.currentPage = page
      this.index()
    },

    getBadgeVariant(val) {
      const result = { variant: '', status: '' }

      if (val === this.status.ERRO) {
        result.variant = 'light-danger'
        result.status = 'Erro'
      }

      if (val === this.status.PENDENTE || val === this.status.ANDAMENTO) {
        result.variant = 'light-warning'
        result.status = 'Em andamento'
      }

      if (val === this.status.SUCESSO) {
        result.variant = 'light-success'
        result.status = 'Concluído'
      }

      if (val === this.status.EXCLUIDO) {
        result.variant = 'light-secondary'
        result.status = 'Excluído'
      }

      return result
    },

    getDisableIcons(status) {
      return !(status === this.status.SUCESSO || status === this.status.ERRO)
    },
  },
}
</script>

<style lang="scss">
.btn-margin {
  margin-right: 6px;
}

.default-btn {
  background: #2772C0;
  color: #FFF;
}

.default-btn:hover {
  color: white;
}

.label-search {
  font-size: 14px;
  font-weight: 400;
  margin: 0 5px 0 0;
}

.m-header {
  margin-bottom: 0;
}

@media(max-width: 1200px) {
  .m-header {
    margin-bottom: 1rem;
  }
}

@media(max-width: 768px) {
  .tips-card {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 1em;

    img {
      display: none;
    }

    button {
      margin: 0 0 1em;
    }

    .tip-text {
      width: 100%;

      p {
        font-size: 0.6rem;
      }
    }
  }

  .import-file-section {
    width: 100%;
    display: grid;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      display: none;
    }

    .instructions {
      width: 100%;

      p {
        font-size: 1rem;
      }
    }

    .file-input {
      width: 100%;
      margin: 1em 0 2em;
    }
  }
}
</style>
