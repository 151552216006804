<template>
  <div
    class="content-wrapper"
  >

    <AddNovoColaborador
        :categoria-select="categoriaSelect"
        :show-modal="modalColaborador.showModal"
        :exibir-empresa-select="showCompanySelectNewCollaborator"
        :empresa-select="groupCompanies"
        :id-empresa="idCompanyNewCollaborator"
        @confirmed="openModalSucesso"
        @canceled="closeModalColaborador"
        size-modal="lg"
    />

    <ConfirmModalVacinacao
        :modal-show="modalConfirm.showModal"
        :case-modal="modalConfirm.typeModal"
        :pessoa-remover="pessoaRemover"
        :tooltip-btn-cancel="modalConfirm.tooltipCancel"
        :disable-cancel="modalConfirm.disableCancel"
        @remove="removeVacinacao"
        @confirmed="modalConfirm.confirmed"
        @canceled="modalConfirm.canceled"
    />

    <AddNovaVacinacao
        :show-modal="modalVacinacao.showModal"
        :lote-select="loteSelect"
        :vacinador-select="vacinadorSelect"
        :endereco-select="addressesNewVaccination"
        :colaborador="modalVacinacao.colaborador"
        :tipoCampanha="campaign.tipo"
        :id-campanha="campaign.idCampaign"
        :empresa="companyNewVaccination"
        :unidade="unitNewVaccination"
        :id-departamento="departamentIdNewVaccination"
        :exibir-endereco-select="true"
        size-modal="lg"
        @canceled="closeModalVacinacaoCancel"
        @confirmed="openModalSucesso"
    />

    <SucessoModal
        :modal-show="modalSucesso.showModal"
        :type-modal="modalSucesso.typeModal"
        :case-modal="modalSucesso.caseModal"
        @confirmed="closeModalSucesso"
    />

    <main class="card hidden_overflow_tabela">
      <div
        class="pt-2" 
      >
        <h2 class="h2-descricao-empresa-campanha">
          <span class="span-descricao-empresa">
          {{ this.group.description }} <span v-if="this.company"> - {{this.company.name}} </span>
          </span>
          <span class="font_size_label span-descricao-campanha"
            >{{ moment().format("YYYY")}} - {{ this.campaign.description }} |
            {{ this.unit.description }} </span
          >
        </h2>
        <div class="pt-2 mx-auto col-11">
          <InfoSideCard :data="doses" />
        </div>
      </div>
      <div
        v-if="loadingData"
        class="d-flex justify-content-center my-3"
      >
        <div
          class="spinner-border text-custom-blue"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <hr>
      <section
          v-if="!loadingData && !showFilter"
          class="d-flex align-items-center filters pb-1 px-2"
      >
        <div
            role="button"
            class="mr-auto"
            @click="
              () => {
                showFilter = true;
              }
            "
        >
          <feather-icon
              size="28"
              icon="FilterIcon"
              class="text-custom-blue"
          />
          <span class="ml-1 font-weight-bold text-custom-blue">FILTRAR</span>
        </div>

        <div class="d-flex mr-2">
          <b-form-checkbox v-model="showCollaboratorsAnotherGroup" @input="toogleShowCollaboratorsAnotherGroup"
          />
          <span>
            Exibir colaboradores vacinados em outro grupo
          </span>
        </div>


        <div>

          <b-button
              v-if="canInsertPerson && canManageCollaborators"
              class="mr-2 cor_botao"
              variant="outline-primary"
              @click="openModalColaborador"
          >
            <feather-icon icon="UserPlusIcon" />
          </b-button>
          <b-button
              v-if="canExport"
              class="mr-2 cor_botao"
              variant="outline-primary"
              @click="downloadSpreadsheet"
          >
            <feather-icon icon="DownloadIcon" />
          </b-button>

        </div>
      </section>
      <section
          v-if="!loadingData && showFilter"
          class="bg-blue filters py-1 px-2"
      >
        <b-row>
          <div
              class="px-1 mr-auto"
              role="button"
              @click="closeFilter"
          >
            <feather-icon
                size="28"
                icon="XIcon"
                class="text-custom-blue"
            />
            <span
                class="ml-1 font-weight-bold text-custom-blue"
            >REMOVER FILTRO</span>
          </div>

          <div>
            <b-button
                v-if="canInsertPerson && canManageCollaborators"
                class="mr-2 cor_botao"
                variant="outline-primary"
                @click="openModalColaborador"
            >
              <feather-icon icon="UserPlusIcon" />
            </b-button>
            <b-button
                v-if="canExport"
                class="mr-2 cor_botao"
                variant="outline-primary"
                @click="downloadSpreadsheet"
            >
              <feather-icon icon="DownloadIcon" />
            </b-button>
          </div>
        </b-row>
        <b-row class="pt-2">
          <b-col
              lg="10"
              class="d-flex p-0"
          >
            <b-col lg="3">
              <b-form-group
                  label="Status"
                  label-for="status-input"
                  label-class="font_size_label"
              >
                <v-select
                    id="status-input"
                    v-model="search.status"
                    variant="custom-blue"
                    class="bg-white"
                    placeholder="Selecione um status"
                    label="label"
                    :options="statusSelect"
                >
                  <span slot="no-options">Nenhuma opção selecionável.</span>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <b-form-group
                  label="Categoria"
                  label-for="categoria-input"
                  label-class="font_size_label"
              >
                <v-select
                    id="categoria-input"
                    v-model="search.categoria"
                    variant="custom"
                    class="bg-white"
                    label="descricao"
                    :options="categoriaSelect"
                    placeholder="Selecione uma categoria"
                >
                  <span slot="no-options">Nenhuma opção selecionável.</span>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <b-form-group
                  label="Nome"
                  label-for="nome-input"
                  label-class="font_size_label"
              >
                <b-form-input
                    id="nome-input"
                    v-model="search.nome"
                    placeholder="Nome da pessoa"
                />
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <b-form-group
                  label="CPF"
                  label-for="cpf-input"
                  label-class="font_size_label"
              >
                <b-form-input
                    id="cpf-input"
                    v-model="search.cpf"
                    v-mask="'###.###.###-##'"
                    placeholder="000.000.000-00"
                />
              </b-form-group>
            </b-col>
          </b-col>
          <b-col lg="2">
            <b-button
                class="mt-2 col-12"
                variant="primary-button"
                @click="filter"
            >
              <feather-icon
                  icon="SearchIcon"
                  class="mr-50"
              />
              <span class="align-middle">Filtrar</span>
            </b-button>
          </b-col>
        </b-row>
      </section>
      <section
          v-if="multiSelect.count && (canInsertMultiple || canExcludeMultiple)"
      >
        <b-row class="d-flex justify-content-center align-items-center">
          <b-col
              lg="4"
              center
          >
            <b-button
                v-if="multiSelect.case === 'success' && canInsertMultiple"
                class="m-1 text-uppercase"
                variant="success"
                @click="vaccinateMultiple"
            >
              <span class="align-middle">VACINAR POR LOTE DE VACINAÇÃO</span>
            </b-button>
            <b-button
                v-if="multiSelect.case === 'danger' && canExcludeMultiple"
                class="m-1 text-uppercase"
                variant="danger"
                @click="unvacinatedMultiple"
            >
              <span class="align-middle">REMOVER VACINA</span>
            </b-button>
          </b-col>
          <b-col
              lg="8"
              class="align-items-center pb-1"
          >
              <span
                  v-if="multiSelect.case === 'success' && canInsertMultiple"
                  class="text-uppercase"
              >
                <feather-icon
                    size="28"
                    icon="XIcon"
                    class="text-custom-blue"
                    role="button"
                    @click="openModalConfirm('desmarcar')"
                />
                {{ multiSelect.count }} selecionados
                <a
                    class="text-custom-blue"
                    role="button"
                    @click="selectAllWithSameStatus(false)"
                >(Marcar todos os Não vacinados)</a>
              </span>
            <span
                v-if="multiSelect.case === 'danger' && canExcludeMultiple"
                class="text-uppercase"
            >
                <feather-icon
                    size="28"
                    icon="XIcon"
                    class="text-custom-blue"
                    role="button"
                    @click="openModalConfirm('desmarcar')"
                />
                {{ multiSelect.count }} selecionados
                <a
                    class="text-custom-blue"
                    role="button"
                    @click="selectAllWithSameStatus(true)"
                >(Marcar todos os vacinados)</a>
              </span>
          </b-col>
        </b-row>
      </section>
      <section
          v-if="!loadingData"
          class="list-vacinacao"
      >
        <b-table
            id="tableVacinacao"
            ref="tableVacinacao"
            responsive
            :sticky-column="true"
            :busy.sync="filtering"
            :fields="table.fields"
            :items="table.items"
            no-local-sorting
            :no-sort-reset="true"
            @sort-changed="ordenarColuna"
        >
          <template
              v-if="canInsertMultiple || canExcludeMultiple"
              #head(id_pessoa_fisica)
          >
            <b-form-checkbox v-if="!search.status || (search.status && search.status.value !== 'Finalizado')"
                v-model="selectAll"
                :indeterminate="selectAll"
            />
          </template>
          <template
              #cell(id_pessoa_fisica)="row"
          >
            <b-form-checkbox
                v-if="((company && row.item.aplicacao.situacao !== 'Finalizada') || (!company && row.item.aplicacao.situacao === 'Aplicada')) && !row.item.aplicacao.outra_empresa_aplicadora"
                v-model="row.item.selected"
                @change="verifyIfTwoStatesSelected(row.item)"
            />
          </template>
          <template #cell(nome_empresa)="row">
            <div class="comprimento_maximo_celulas">
              <span class="comprimento_maximo_celulas "
                    :title="row.item.razao_social_empresa.length > 45 ? row.item.razao_social_empresa : null" v-b-tooltip>
                {{ row.item.razao_social_empresa ? row.item.razao_social_empresa : '' }}
              </span>
              <br/>
              <span class="font-weight-bold comprimento_maximo_celulas"
                    :title="row.item.nome_empresa.length > 45 ? row.item.nome_empresa : null" v-b-tooltip>
                {{ row.item.nome_empresa ? row.item.nome_empresa : '' }}
             </span>
            </div>
          </template>
          <template #cell(nome)="row">
              <span class="font-weight-bold comprimento_maximo_celulas">
                {{ row.item.nome }}
              </span>
          </template>

          <template #cell(cpf)="row">
              <span class="font-weight-bold">
                {{ row.item.cpf | VMask("###.###.###-##") }}
              </span>
          </template>
          <template #cell(categoria)="row">
              <span class="font-weight-bold"
              >
                {{ row.item.categoria }}
              </span>
          </template>
          <template #cell(status)="row">
            <b-button
                v-if="foiVacinadoPorOutraEmpresa(row.item)"
                v-b-tooltip
                :title="formatDocumentCompany(row.item.aplicacao)"
                variant="link"
                class="id-empresa-aplicadora"
            >
              <b-form-checkbox
                  :checked="true"
                  :disabled="true"
                  class="custom-control-info"
                  name="check-button"
                  switch
              >
                  <span class="switch-icon-left">
                    <span
                        v-text="getSituacaoLabel(row.item.aplicacao.situacao)"
                    />
                  </span>
              </b-form-checkbox>
            </b-button>

            <b-form-checkbox
                v-else
                v-model="row.item.vacinado"
                :checked="false"
                class="custom-control-success"
                name="check-button"
                switch
                :disabled="deveDesabilitarBotao(row.item)"
                @change="changedState($event, row.item)"
            >
                <span class="switch-icon-left">
                  <span
                      v-text="getSituacaoLabel(row.item.aplicacao.situacao)"
                  />
                </span>
              <span class="switch-icon-right">
                  <span>Não vacinado</span>
                </span>
            </b-form-checkbox>
          </template>
        </b-table>
        <div class="pb-2 px-1">
            <span
                v-if="without"
                class="ml-1"
            >Nenhum colaborador encontrado</span>
          <b-col
              sm="12"
              class="mt-2"
              v-if="!without"
          >
            <CustomPagination
                :paginacao="paginationData"
                @page-cliked="updateCurrentPage"
            />
          </b-col>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  VBTooltip,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'
import InfoSideCard from '@/views/components/custom/cards/InfoSideCard.vue'
import AddNovoColaborador from '@/views/components/custom/modals/vacinacao/Add-novo-colaborador.vue'
import AddNovaVacinacao from '@/views/components/custom/modals/vacinacao/Add-nova-vacinacao.vue'
import ConfirmModalVacinacao from '@/views/components/custom/modals/vacinacao/ConfirmModalVacinacao.vue'
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue'
import moment from 'moment';
import {actions, subjects} from "@/libs/acl/rules";
import {successMessage} from "@/libs/sweetalerts";
import { getInfoUserDepartamento } from "@core/utils/store/getStore";
import chavePerfil from '@/enum/chavePerfil'

export default {
  title: 'Lista de registros de vacinação',

  components: {
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    vSelect,
    CustomPagination,
    BFormCheckbox,
    InfoSideCard,
    AddNovoColaborador,
    AddNovaVacinacao,
    ConfirmModalVacinacao,
    SucessoModal,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    params: {
      type: Object,
      default: () => {},
    }
  },

  data() {
    return {
      moment,
      doses: [
        {
          icon: 'ClipboardIcon',
          color: 'light-primary',
          title: 0,
          subtitle: 'Doses Contratadas',
        },
        {
          icon: 'CheckIcon',
          color: 'light-success',
          title: 0,
          subtitle: 'Doses Aplicadas',
        },
        {
          icon: 'LoaderIcon',
          color: 'light-warning',
          title: 0,
          subtitle: 'Doses Pendentes',
        },
      ],
      table: {
        fields: [
          {
            key: 'id_pessoa_fisica',
            label: '',
            sortable: false,
            class: 'mxw-15 pr-0',
          },
          {
            key: 'nome_empresa', label: 'EMPRESA', sortable: true, class: 'mw-200',
          },
          {
            key: 'nome', label: 'NOME', sortable: true, class: 'mw-200',
          },
          {
            key: 'cpf', label: 'CPF', sortable: false, class: 'mw-150',
          },
          {
            key: 'categoria',
            label: 'CATEGORIA',
            sortable: false,
            class: 'mw-100',
          },
          {
            key: 'status', label: 'STATUS', sortable: false, class: 'mw-50',
          },
        ],
        items: [],
        optionsSize: [10, 25, 50, 100],
        columnOrder: 'nome',
        order: 'asc',
      },
      paginationData: {
        currentPage: 1,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
        defaultSize: 10,
      },
      loadingData: true,
      filtering: false,
      canInsertMultiple: false,
      canExcludeMultiple: false,
      showFilter: false,
      canInsertPerson: false,
      canExport: false,
      categoriaSelect: [],
      timeID: null,
      statusSelect: [
        {
          label: 'Finalizado',
          value: 'Finalizado',
        },
        {
          label: 'Vacinado',
          value: 'Vacinado',
        },
        {
          label: 'Não vacinado',
          value: false,
        },
      ],
      search: {
        status: '',
        categoria: '',
        nome: '',
        cpf: '',
      },
      without: false,
      searchTerm: '',
      modalVacinacao: {
        showModal: false,
        colaborador: null,
      },
      vacinadorSelect: [],
      loteSelect: [],
      companyNewVaccination: null,
      unitNewVaccination: null,
      addressesNewVaccination: [],
      modalSucesso: {
        showModal: false,
        typeModal: 'success',
        caseModal: 'criacao',
      },
      modalColaborador: {
        showModal: false,
      },
      groupCompanies: [],
      modalConfirm: {
        showModal: false,
        typeModal: 'remover',
        confirmed: this.closeModalConfirm,
        canceled: this.closeModalConfirm,
        disableCancel: false,
        tooltipCancel: '',
      },
      pessoaRemover: 0,
      multiSelect: {
        count: 0,
        case: 'danger',
      },
      modalSucessoCancelamento: {
        showModal: false,
        typeModal: 'success',
        caseModal: 'cancelamento',
      },
      selectAll: false,
      unselectItemColab: null,
      showCompanySelectNewCollaborator: true,
      idCompanyNewCollaborator: null,
      canManageCollaborators: false,
      showCollaboratorsAnotherGroup: false,
      departamentIdNewVaccination: null,
      departamentUserLogged: null
    }
  },

  computed: {
    group() {
      return this.params.grupo;
    },
    campaign() {
      return this.params.campanha;
    },
    unit() {
      return this.params.unidade;
    },
    company() {
      return this.params.empresa;
    }
  },

  watch: {
    selectAll(val) {
      this.verifyIfTwoStatesSelected()
      if (!val) {
        this.unselectAll()
        return
      }
      this.openModalConfirm('marcar')

      if (this.company) {
        this.modalConfirm.disableCancel = false;
        this.modalConfirm.tooltipCancel = '';
      } else {
        this.modalConfirm.disableCancel = true;
        this.modalConfirm.tooltipCancel = 'Não é possível vacinar em lote em um grupo de empresas.';
      }

      this.modalConfirm.confirmed = this.selectAllVaccinated
      this.modalConfirm.canceled = this.selectAllNonVaccinated
    },
  },

  async mounted() {
    try {
      this.loadingData = true;
      this.departamentUserLogged = getInfoUserDepartamento('id_departamento');

      await Promise.all([
        this.loadGroupQuantities(),
        this.loadGroupCollaborators(),
        this.loadCategories(),
        this.loadVaccinators(),
        this.company ? this.loadCollaboratorsPermissionFromCompany() :  null
      ]);

      this.initializePermissions();

      const perfil = this.$store.state.sessions.userData.perfis[0].chave;
      const isVaccinator = perfil === chavePerfil.VACINADOR_PADRAO || perfil === chavePerfil.VACINADOR_ADMIN;

      if (this.$can(actions.INSERIR, subjects.EMPRESA_COLABORADOR) && !isVaccinator)  {
        await this.loadGroupCompanies(this.departamentUserLogged)
      }

      if (!this.company && this.groupCompanies.length > 0) {
        this.canManageCollaborators = true;
      }

      this.departamentIdNewVaccination = this.unit.idDepartment;
      this.showCompanySelectNewCollaborator = this.company === null;
      this.idCompanyNewCollaborator = this.company?.idCompany ?? null;
    } catch (error) {
      console.error("Erro ao carregar os dados:", error);
    } finally {
      this.loadingData = false;
    }
  },

  methods: {
    initializePermissions(){
      this.canExclude = this.verifyPermissions(actions.EXCLUIR)
      this.canExcludeMultiple = this.verifyPermissions(actions.EXCLUIR_MULTIPLO)
      this.canInsert = this.verifyPermissions(actions.INSERIR)
      this.canInsertPerson = this.$can(
          actions.INSERIR,
          subjects.EMPRESA_COLABORADOR,
      )
      this.canInsertMultiple = this.verifyPermissions(actions.INSERIR_MULTIPLO) && this.company
      this.canExport = this.verifyPermissions(actions.EXPORTAR)
    },
    selectAllVaccinated() {
      this.unselectAllWithSameStatus(false)
      const toSelect = this.table.items.filter(item => item.vacinado && !item.aplicacao.outra_empresa_aplicadora)

      toSelect.map(item => {
        item.selected = true
      })
      this.verifyIfTwoStatesSelected()
      this.closeModalConfirm()
    },
    selectAllNonVaccinated() {
      this.unselectAllWithSameStatus(true)
      const toSelect = this.table.items.filter(item => !item.vacinado)

      toSelect.map(item => {
        item.selected = true
      })
      this.verifyIfTwoStatesSelected()
      this.closeModalConfirm()
    },
    selectAllWithSameStatus(status) {
      const toSelect = this.table.items.filter(item => item.vacinado == status)

      toSelect.map(item => {
        item.selected = true
      })
      this.$refs.tableVacinacao.refresh()
    },
    unselectAllWithSameStatus(status) {
      const toSelect = this.table.items.filter(item => item.vacinado == status)

      toSelect.map(item => {
        item.selected = false
      })
      this.$refs.tableVacinacao.refresh()
    },
    verifyPermissions(action) {
      const permission = this.$can(
          actions[action],
          subjects.REGISTRO_VACINACAO_SESI,
      )

      return [permission].includes(true)
    },
    async loadGroupQuantities() {
      const params = {
        idCampanha: this.campaign.idCampaign,
        idGrupo: this.group.idGroup,
        idUnidade: this.unit.idUnit
      };

      if (this.company) {
        params['idEmpresa'] = this.company.idCompany;
      }

      await this.$http.get(this.$api.adesaoNacionalQuantitativos(), {params: params}).then(({data}) => {
        this.doses[0].title = data.quantidadeTotalAderida;
        this.doses[1].title = data.quantidadeAplicada;
        this.doses[2].title = data.quantidadeNaoAplicada;
      })
    },
    async loadGroupCollaborators() {
      const params = {
        paginacao: true,
        tamanho: this.paginationData.defaultSize,
        pagina: this.paginationData.currentPage,
        colunaOrdenar: this.table.columnOrder,
        vacinado: this.search.status ? this.search.status.value : '',
        ordem: this.table.order,
        ordem_vacinados: 'desc',
        id_campanha: this.campaign.idCampaign,
        id_categoria: this.search.categoria
            ? this.search.categoria.id_categoria
            : '',
        cpf: this.search.cpf,
        id_unidade: this.unit.idUnit,
        ano: moment().format('YYYY'),
        ativo: true,
        nome: this.search.nome,
        id_grupo: this.group.idGroup,
        vacinado_outro_grupo: this.showCollaboratorsAnotherGroup
      };

      if (this.company) {
        params['id_empresa'] = this.company.idCompany;
      }

      await this.$http.get(this.$api.colaborador(), {params: params}).then(({data}) => {
        this.table.items = data.data;

        if (data.data.length === 0) {
          this.without = true
        }

        this.startPagination(data);
        this.filtering = false;
      })
    },
    async loadGroupCompanies(idDepartment) {
      await this.$http.get(this.$api.getAndPutAndDeleteGrupo(this.group.idGroup)).then(({data}) => {
        const companies = data.empresa_operador.filter(empresaOperador => {
          return empresaOperador.grupo_operadores.some(go => {
            return go?.id_operador === idDepartment
          });
        });

        this.groupCompanies = companies.map(company => ({
          id_empresa: company.id_empresa,
          nome_empresa: company.nome_empresa,
          documento_formatado: company.documento_formatado,
          razao_social: company.razao_social,
          documento: company.documento,
        }))
      })
    },
    async loadCollaboratorsPermissionFromCompany() {
      await this.$http.get(this.$api.getShowAndEditEmpresa(this.company.idCompany)).then(({data}) => {
        this.canManageCollaborators = data['pode_gerenciar_colaboradores'];
      })
    },
    async vaccinateMultiple() {
      const selected = this.table.items.filter(item => item.selected);

      await this.loadLotes(this.company.idCompany);
      await this.loadAddressesNewVaccination(this.company.idCompany);

      this.companyNewVaccination = {id_empresa: this.company.idCompany};
      this.unitNewVaccination = {id_unidade: this.unit.idUnit};

      this.modalVacinacao.colaborador = selected;
      this.openModalVacinacao();
    },
    unvacinatedMultiple() {
      const selected = this.table.items.filter(item => item.selected && item.aplicacao.situacao != 'Finalizada')
      this.modalVacinacao.colaborador = selected
      this.pessoaRemover = selected.length || 1

      this.openModalConfirm('removerMultiplo')
    },
    unselectAll() {
      this.multiSelect.count = 0
      this.selectAll = false
      this.table.items.map(item => {
        item.selected = false
      })
      this.closeModalConfirm()
    },
    removeVacinacao() {
      const { colaborador } = this.modalVacinacao
      this.loadingData = true
      this.unselectAll()
      this.pessoaRemover = colaborador.length || 1

      const pessoas = colaborador.map(element => ({
        id_aplicacao: element.aplicacao.id_aplicacao,
        motivo_cancelamento: 'Cancelamento realizado via sistema',
      }))

      this.$http
          .post(this.$api.vacinacaoCancelar(), { pessoas })
          .then(async response => {
            if (response.status === 201) {
              successMessage('Cancelamento realizado com sucesso!')

              this.table.items = []

              this.paginationData = {
                currentPage: 1,
                totalLines: 0,
                fromLine: 0,
                toLine: 0,
                defaultSize: 10,
              }

              await this.loadGroupCollaborators();
              await this.loadGroupQuantities();

              this.loadingData = false;
            }
          })
          .catch(err => {
            this.modalError.caseModal = 'naoInativo'
            this.openModalError()
          })
    },
    openModalError() {
      this.modalError.showModal = true
    },
    startPagination(data) {
      this.paginationData.currentPage = data.meta.current_page
      this.paginationData.totalLines = data.meta.total
      this.paginationData.fromLine = data.meta.from
      this.paginationData.toLine = data.meta.to
    },
    ordenarColuna(sort) {
      this.table.columnOrder = sort.sortBy;
      this.table.order = sort.sortDesc ? 'desc' : 'asc';

      this.filtering = true;

      this.loadGroupCollaborators();
    },
    unselectItem() {
      this.table.items.map(item => {
        if (item.id_pessoa_fisica == this.unselectItemColab.id_pessoa_fisica) {
          item.selected = false
        }
      })
      this.$refs.tableVacinacao.refresh()
      this.unselectItemColab = null
      this.closeModalConfirm()
    },
    verifyIfTwoStatesSelected(i = null) {
      const selected = this.table.items.filter(
          item => item.selected
              && item.vacinado
              && item.aplicacao.situacao != 'Finalizada',
      )
      const notSelected = this.table.items.filter(
          item => item.selected && !item.vacinado,
      )

      if (selected.length && notSelected.length) {
        this.openModalConfirm('selecionar')

        this.modalConfirm.confirmed = this.unselectItem
        this.unselectItemColab = i
        return
      }

      if (selected.length > 1) {
        this.multiSelect.count = selected.length
        this.multiSelect.case = 'danger'
      }

      if (notSelected.length > 1) {
        this.multiSelect.count = notSelected.length
        this.multiSelect.case = 'success'
      }

      if (selected.length <= 1 && notSelected.length <= 1) {
        this.multiSelect.count = 0
      }
    },
    foiVacinadoPorOutraEmpresa(item) {
      return item.aplicacao.outra_empresa_aplicadora
    },
    formatDocumentCompany(item) {
      const titleDocument = `Vacinado na empresa ${item.nome_fantasia_empresa_aplicadora} de `

      if (item.cpf_empresa_aplicadora) {
        return `${titleDocument}CPF ${this.$options.filters.VMask(item.cpf_empresa_aplicadora, '###.###.###-##')}`
      }
      return `${titleDocument}CNPJ ${this.$options.filters.VMask(item.cnpj_empresa_aplicadora, '##.###.###/####-##')}`
    },
    getSituacaoLabel(situacao) {
      return situacao == 'Finalizada' ? situacao : 'Vacinado'
    },
    deveDesabilitarBotao(item) {
      return (
          (!item.vacinado && !this.canInsert)
          || (item.vacinado && !this.canExclude)
          || item.aplicacao.situacao == 'Finalizada'
      )
    },
    async changedState(state, colaborador) {
      if (state) {
        this.companyNewVaccination = {id_empresa: colaborador.id_empresa};
        this.unitNewVaccination = {id_unidade: this.unit.idUnit};

        await this.loadAddressesNewVaccination(colaborador.id_empresa);
        await this.loadLotes(colaborador.id_empresa);

        this.modalVacinacao.colaborador = [colaborador]
        this.openModalVacinacao()
      } else {
        this.modalVacinacao.colaborador = [colaborador]
        this.openModalConfirm('remover')
      }
    },
    async loadAddressesNewVaccination(companyId) {
      const addressesWithAdhesion = await this.getAddressesWithAdhesion(
          companyId,
          this.campaign.idCampaign,
          this.group.idGroup
      );

      await this.$http.get(this.$api.getShowAndEditEmpresa(companyId)).then(({data}) => {
        this.addressesNewVaccination = data.enderecos.filter(
            address =>
                address.id_unidade === this.unit.idUnit &&
                addressesWithAdhesion.includes(address.id_empresa_endereco)
        );
      });
    },
    async getAddressesWithAdhesion(companyId, campaignId, groupId) {
      const params = {
        id_empresa: companyId,
        id_campanha: campaignId,
        id_grupo: groupId,
      }

      return await this.$http.get(this.$api.empresasComAdesao(), {params: params}).then(({data}) => {
        return data?.[0]?.enderecos_com_adesao ?? [];
      });
    },

    updateCurrentPage(page) {
      this.paginationData.currentPage = page
      this.filtering = true
      this.loadGroupCollaborators()
    },
    openModalVacinacao() {
      this.modalVacinacao.showModal = true
    },
    openModalColaborador() {
      this.modalColaborador.showModal = true
    },
    openModalConfirm(type) {
      this.modalConfirm.showModal = true
      this.modalConfirm.typeModal = type
      if (type == 'desmarcar') {
        this.modalConfirm.confirmed = this.unselectAll
        this.modalConfirm.canceled = this.closeModalConfirm
      }
    },
    downloadSpreadsheet() {
      const params = {
        paginacao: false,
        vacinado: this.search.status ? this.search.status.value : '',
        ordem: this.table.order,
        id_campanha: this.campaign.idCampaign,
        id_categoria: this.search.categoria
            ? this.search.categoria.id_categoria
            : '',
        cpf: this.search.cpf,
        ano: moment().format('YYYY'),
        ativo: true,
        id_unidade: this.unit.idUnit,
        id_grupo: this.group.idGroup,
      };

      if (this.company) {
        params['id_empresa'] = this.company.idCompany;
      }

      this.$http
          .get(this.$api.colaborador(), {
            params: params,
          })
          .then(({ data }) => {
            const content = data.data.map(item => ({
              nome: item.nome,
              cpf: item.cpf,
              empresa: item.nome_empresa,
              'data de nascimento': moment(item.data_nascimento).format('DD/MM/YYYY'),
              categoria: item.categoria,
              vacinado: item.vacinado ? 'Vacinado' : 'Não vacinado',
            }))

            this.$helpers.downloadArchiveXlsx(content, 'planilha_vacinacao.xlsx')
          })
    },
    closeFilter() {
      this.showFilter = false;

      this.clearSearch();

      this.filtering = true;
      this.paginationData.currentPage = 1;

      this.loadGroupCollaborators()
    },
    clearSearch() {
      this.search.nome = ''
      this.search.status = ''
      this.search.categoria = ''
      this.search.cpf = ''
    },
    async loadCategories() {
      await this.$http.get(this.$api.getEmpresaCategoria()).then(({data}) => {
        this.categoriaSelect = data;
      })
    },
    async loadLotes(companyId = null) {
      const parameters = {
        id_unidade: this.unit.idUnit,
        id_campanha: this.campaign.idCampaign,
        id_departamento: this.unit.idDepartment,
        ativo: false
      }

      if (companyId) {
        parameters['id_empresa'] = companyId;
      }

      const lotes = await this.$http.get(this.$api.lote(), {
        params: parameters,
      });

      const lotesFiltered = lotes.data.filter(lote => lote.saldo > 0)

      this.loteSelect = lotesFiltered.sort((a, b) => a.numero_lote.localeCompare(b.numero_lote))
    },
    filter() {
      if (this.searchTerm.length >= 2 || this.searchTerm.length == 0) {
        this.without = false

        clearTimeout(this.timeID)
        this.timeID = setTimeout(() => {
          this.paginationData.currentPage = 1
          this.filtering = true
          this.loadGroupCollaborators()
        }, 500)
      }
    },
    closeModalConfirm() {
      this.modalConfirm.tooltipCancel = '';
      this.modalConfirm.disableCancel = false;
      this.modalConfirm.showModal = false
      this.pessoaRemover = 0
    },
    async loadVaccinators() {
      const params = {
        unidadesId: [this.unit.idUnit],
        existsPessoaFisicaDocumento: 1,
        departamentoId: this.unit.idDepartment,
        grupoId: this.group.idGroup,
      }
      await this.$http.get(this.$api.vacinadores(), {params}).then(({data}) => {
        this.vacinadorSelect = data;
      });
    },
    closeModalVacinacaoCancel() {
      const colab = this.modalVacinacao.colaborador ?? [];

      if(colab.length == 1) {
        let selectedColab = this.table.items.find(
            item => item.id_pessoa_fisica == colab[0].id_pessoa_fisica && item.id_empresa == colab[0].id_empresa
        )

        selectedColab.vacinado = !selectedColab.vacinado
      }

      this.modalVacinacao.colaborador = ''
      this.modalVacinacao.showModal = false
    },
    openModalSucesso() {
      this.closeModalColaborador();
      this.modalVacinacao.showModal = false
      this.modalSucesso.showModal = true
    },
    closeModalColaborador() {
      this.modalColaborador.showModal = false
    },
    async closeModalSucesso() {
      this.modalSucesso.showModal = false;
      this.loadingData = true;

      await Promise.all([
        this.loadGroupQuantities(),
        this.loadGroupCollaborators()
      ]);

      this.loadingData = false;
    },
    toogleShowCollaboratorsAnotherGroup() {
      this.showFilter = false;

      this.clearSearch();

      this.filtering = true;
      this.paginationData.currentPage = 1;

      this.loadGroupCollaborators();
    }
  },
}
</script>

<style scoped>
.font_size_label {
  font-size: 15px;
  margin-top: -5px;
}

.custom {
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  font-weight: 400;
}

.cor_botao {
  color: #2772c0 !important;
  border: 1px solid #2772c0 !important;
}

.altura_max_scrollbar {
  max-height: 100px;
  padding-right: 15px;
}

.comprimento_maximo_celulas {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
}

.hidden_overflow_tabela {
  overflow: hidden;
}

.custom-switch >>> .custom-control-label .switch-icon-left {
  left: 20px;
  transform: translateX(8px);
}

.custom-switch >>> .custom-control-label::before {
  width: 130px;
}

.custom-switch
>>> .custom-control-input:checked
~ .custom-control-label::after {
  transform: translateX(7.5rem);
}

.custom-switch >>> .custom-control-label .switch-icon-right {
  transform: translateX(90px);
  width: 100px;
}

#tableVacinacao >>> thead th {
  border-bottom: 2px solid #2772c0 !important;
  background-color: #2772c0 !important;
  color: white;
}

.bg-blue {
  background-color: #f2f7fc;
}

.id-empresa-aplicadora {
  width: 100%;
  text-align: left;
  display: inline-block;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  border-color: none !important;
  background-color: none !important;
}

.span-descricao-campanha {
  background: #F5F6F9;
  border-radius: 20px;
  padding: 5px 10px;
  text-align: right;
  margin-top: 0;
}

.span-descricao-empresa {
  flex: 1;
}

.h2-descricao-empresa-campanha {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

</style>
